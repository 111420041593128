exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-consultations-tsx": () => import("./../../../src/pages/articles/consultations.tsx" /* webpackChunkName: "component---src-pages-articles-consultations-tsx" */),
  "component---src-pages-articles-microchipping-tsx": () => import("./../../../src/pages/articles/microchipping.tsx" /* webpackChunkName: "component---src-pages-articles-microchipping-tsx" */),
  "component---src-pages-articles-petinfo-tsx": () => import("./../../../src/pages/articles/petinfo.tsx" /* webpackChunkName: "component---src-pages-articles-petinfo-tsx" */),
  "component---src-pages-articles-surgery-tsx": () => import("./../../../src/pages/articles/surgery.tsx" /* webpackChunkName: "component---src-pages-articles-surgery-tsx" */),
  "component---src-pages-articles-vaccinations-tsx": () => import("./../../../src/pages/articles/vaccinations.tsx" /* webpackChunkName: "component---src-pages-articles-vaccinations-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-covid-19-tsx": () => import("./../../../src/pages/covid-19.tsx" /* webpackChunkName: "component---src-pages-covid-19-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

