import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Popover, Transition } from '@headlessui/react';
import { isSameMonth } from 'date-fns';
import Logo from '../images/LogoNew.svg';

interface ServiceItem {
  text: string;
  link: string;
}

interface HeaderItem {
  text?: string;
  link?: string;
  replacement?: React.JSX.Element;
}

interface HeaderProps {
  text?: string;
  link?: string;
  child?: React.JSX.Element;
  replacement?: React.JSX.Element;
}

export function HeaderItem({ text, link = '', child, replacement }: HeaderProps): React.JSX.Element {
  return (
    <div className="relative">
      {replacement || (
        <Link className="btn header-btn" activeClassName="active" to={link}>
          {text}
        </Link>
      )}
      {child}
    </div>
  );
}

interface HeaderPopoverProps {
  text: string;
  children: ServiceItem[];
}

export function HeaderPopover({ text, children }: HeaderPopoverProps): React.JSX.Element {
  return (
    <Popover className="header-popover relative z-50">
      <Popover.Button className="btn inline-flex w-full items-center justify-center">
        {text}
        <FontAwesomeIcon icon={faChevronDown} className="-mr-1 ml-2 transition-transform ui-open:rotate-180 ui-open:transform" aria-hidden="true" />
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute right-0 z-50 mt-2 w-auto origin-top-right divide-y divide-gray-300 overflow-hidden rounded-md bg-white font-sans font-bold drop-shadow-md">
          {children.map((s: ServiceItem) => (
            <div key={s.text}>
              <Link
                to={s.link}
                className="flex px-4 py-2 text-black no-underline hover:bg-gray-100"
                activeClassName="ring-2 ring-inset ring-crvc-orange bg-crvc-blue text-white hover:bg-crvc-blue-400 hover:text-white"
              >
                {s.text}
              </Link>
            </div>
          ))}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default function Header() {
  const christmasLightCount = 60;

  const servicesChildren: ServiceItem[] = [
    {
      text: 'Consultations',
      link: '/articles/consultations',
    },
    {
      text: 'Surgery',
      link: '/articles/surgery',
    },
    {
      text: 'Vaccinations',
      link: '/articles/vaccinations',
    },
    {
      text: 'Microchipping',
      link: '/articles/microchipping',
    },
  ];

  const headerItems: HeaderItem[] = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'About Us',
      link: '/about',
    },
    {
      text: 'Services',
      replacement: <HeaderPopover text="Services">{servicesChildren}</HeaderPopover>,
    },
    {
      text: 'Useful Information',
      link: '/articles/petinfo',
    },
    {
      text: 'Contact Us',
      link: '/contact',
    },
  ];

  const headerElements: React.JSX.Element[] = headerItems.map((h: HeaderItem) => <HeaderItem key={h.text} replacement={h.replacement} text={h.text} link={h.link} />);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          slug
        }
      }
    }
  `);

  const liTags: React.JSX.Element[] = [];

  for (let i = 0; i < christmasLightCount; i += 1) {
    liTags.push(<li key={i} />);
  }

  const [isChristmas, setIsChristmas] = useState(false);

  useEffect(() => {
    const year = new Date().getFullYear();
    const isDecember = isSameMonth(new Date(), new Date(`${year}-12-25`));
    setIsChristmas(isDecember);
  });

  return (
    <div className={`${isChristmas ? 'event-christmas relative pt-12' : ''} grid grid-cols-1 justify-items-center gap-2 py-4 text-center text-white md:gap-4`}>
      {isChristmas ? <ul className="christmas-lights">{liTags}</ul> : null}
      <div className="grid grid-cols-[1fr_1fr] gap-2 md:grid-cols-[auto_auto]">
        <img loading="eager" className="aspect-[217/96] max-h-20 w-auto self-end justify-self-end md:max-h-24" src={Logo} alt="Logo" />
        <div className="justify-self-start text-left">
          <h2 className="m-0 w-full p-0">Camms Road</h2>
          <h2 className="m-0 w-full p-0">Veterinary Clinic Cranbourne</h2>
          <span className="m-0 hidden p-0 font-display text-sm uppercase tracking-wide md:block">{data.site.siteMetadata.slug}</span>
        </div>
      </div>

      <div className="header-items-grid text-md grid grid-cols-2 gap-1 font-display md:grid-cols-3 md:gap-2 md:text-lg lg:grid-flow-col lg:grid-cols-none">{headerElements}</div>

      {/* <div className="alert warning" role="alert">
        <div className="flex">
          <div className="icon">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <div>
            <p>
              <strong>Important COVID-19 Information - </strong>
              <Link to="/covid-19">Click here</Link>
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className="alert warning" role="alert">
        <div className="flex">
          <div className="icon">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <div>
            <p>
              <strong>Camms Road Closure - Information - </strong>
              <Link to="/road-closure">Click here</Link>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
